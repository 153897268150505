/* nav slider */
.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 56px);
}

/* search */
.search-bar {
  display: block;
}

/* menu */
.pcoded-navbar {
  .pcoded-inner-navbar {
    >li {
      >a.active {
        background: rgba(202, 202, 202, 0.3);

        &:before {
          background: $primary-color;
        }
      }
    }

    li {
      >a.active {
        font-weight: 600;
      }

      .pcoded-submenu>li {

        &.active,
        &:focus,
        &:hover {

          >a,
          >a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

        >a.active {
          &:before {
            background: $primary-color;
          }
        }
      }
    }

    >.pcoded-menu-caption {

      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }
}

.bootstrap-tagsinput {
  padding: 2px 8px;
  background-color: white;
}

.react-tags__search {
  padding: 0;
}

.react-tags__selected-tag-name {
  font-size: 12px;
  color: white;
}

.react-tags__selected-tag {
  background-color: #2ed8b6 !important;
  padding: 3px 5px !important;
  border: none;
}

.react-tags .react-tags__selected-tag:after {
  color: white;
  font-size: 10px;
  font-weight: 600;

}

.select__multi-value__label {
  color: white !important;
}

.select__multi-value__remove {
  color: white !important;
}

.select__multi-value {
  background-color: #2ed8b6 !important;
}

/* box-layout */
body.box-layout .pcoded-navbar {
  height: 100%;
}

/* menu-styler */
.menu-styler {

  h5,
  h6 {
    color: #222 !important;
  }
}

/* datatable icons */
.dataTables_wrapper table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: none !important;
}

table.dataTable .sorting:after,
table.dataTable .sorting_asc:after,
table.dataTable .sorting_desc:after {
  top: 16px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: feather !important;
  content: "\e82a";
}

table.dataTable thead .sorting_asc:after {
  content: "\e82d";
}

table.dataTable thead .sorting_desc:after {
  content: "\e82a";
}

/* card header */
.card .card-header+.collapse {
  .card-body {
    padding-top: 0;
  }
}

/* notification */
.css-e110bw {
  top: 70px !important;

  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.user-profile-list table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
}

.user-profile-list table th,
.user-profile-list table td {
  padding: 1.05rem 0.75rem !important;
}

.user-profile-list table th {
  position: relative;
}

.user-profile-list table th .feather {
  position: absolute;
  bottom: 19px;
  right: 8px;
  display: block;
  opacity: 0.8;
}

.user-profile-list {
  .form-control {
    background: transparent;
  }
}

.modal-footer {
  padding: 20px 25px !important;
}

.react-toast-notifications__container {
  margin-top: 62px;
  z-index: 1051 !important;

  .alert-dismissible .close {
    top: -12px;
    right: -66px;
  }
}

.rodal-dialog {
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  transform: translateY(-50%);

  .card {
    box-shadow: none;
    margin-bottom: 0px;

    .card-footer {
      padding-bottom: 0px;
    }

  }
}

.hideobj {
  display: none;
}

.pointer {
  cursor: pointer;
}

.f-13 {
  font-size: 13px;
}

.min-h-200 {
  min-height: 200px;
}

.min-h-300 {
  min-height: 300px;
}

.min-h-450 {
  min-height: 450px;
}

.min-h-500 {
  min-height: 500px;
}

.rb-w label {
  width: 100%;
}

.deni-react-treeview-item-container .checkbox {
  width: 18px;
  height: 18px;
  border: solid 1px;
  border-color: #adcce6;
  border-radius: 2px;
  display: inline-table;
  cursor: pointer;
  margin-right: 3px;
}

@media only screen and (max-width: 1199px) {
  .hover-blk {
    display: none;
  }
}

@media (max-width: 750px) {
  .q-view .content {
    width: 450px;
  }
}

@media (max-width: 460px) {
  .q-view .content {
    width: 300px;
  }
}

/*Relacionado ao popover do modal do desacordo  para exibição das regras do componente toolBarTableCte*/
.custom-popover {
  z-index: 1080 !important;
}


.vertical-line {
  width: 1px;
  height: 50px;
  /* Ajuste a altura desejada */
  background-color: #ccc;
  margin: 0 10px;
}

.swal-popup-class {
  z-index: 99999 !important; // Ajuste o valor do z-index conforme necessário para garantir que o modal fique acima de outros elementos.
}

.custom-datepicker {
  // Ajuste para o datepicker não fique acima dos demais elementos.
  z-index: 0 !important;
}



.btn-sweetalert-custom {
  // Classe para padronizar os botões do sweetalert
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 0.375rem 0.95rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 2px !important;
}



//Classe personalizada para o componente ImputTags
/***************** INICIO *****************/
.rti--tag-custom {
  --rti-bg: #fff !important;
  /* Cor de fundo */
  --rti-border: #ccc !important;
  /* Cor da borda */
  --rti-main: #3182ce !important;
  /* Cor principal (texto, etc.) */
  --rti-radius: 0.375rem !important;
  /* Raio de borda */
  --rti-s: 0.5rem !important;
  /* Espaçamento */
  --rti-tag: #edf2f7 !important;
  /* Cor das tags */
  --rti-tag-remove: #e53e3e !important;
  /* Cor de remoção das tags */
  --rti--tag-font: 0.75rem !important;
  /* Tamanho da fonte equivalente a 13px*/

  background-color: var(--rti-bg) !important;
  border: 1px solid var(--rti-border) !important;
  color: var(--rti-main) !important;
  border-radius: var(--rti-radius) !important;
  font-size: var(--rti--tag-font) !important;
  display: flex !important;

}


.rti--tag-custom button {
  align-items: center !important;
  appearance: none !important;
  background: var(--rti-main) !important;
  border: none !important;
  border-radius: 50% !important;
  color: white !important;
  cursor: pointer !important;
  display: inline-flex !important;
  font-size: 12px !important;
  height: 15px !important;
  justify-content: center !important;
  line-height: 0 !important;
  margin-left: 8px !important;
  padding: 0 !important;
  width: 15px !important;
  font-size: 0.4375rem !important;
}

.rti--tag-custom button:hover {
  background: #4197e7 !important;
}

.rti--tag-custom button:focus {
  outline: none !important;
  outline: none !important;
}

/***************** FIM *****************/

.MuiInputBase-input {
  font-size: 0.875rem !important;
}


.transparent-btn {
  opacity: 0 !important;
  pointer-events: none !important;
}


.enlarge-on-hover:hover {
  //Efeito para a Badge do pacote do captura xml
  transform: scale(1.1);
  transition: transform 0.2s;
}


.pcoded-navbar .pcoded-inner-navbar li>a {
  //Padding do menu vertifical do sistema
  padding: 0px 20px !important;
}

.MuiTableRow-root:hover td {
  background-color: none !important;
}


.MuiTableCell-root:hover {
  outline: none !important;
  ;
  outline-offset: -1px;
  text-overflow: inherit !important;
  ;

}

button:focus {
  outline: none !important;
}


/* Classes custons para o daterangepicker modal logs*/
.rs-picker-daterange-menu {
  z-index: 1080 !important;
}


.widget-profile-card-3-custom {
  background-image: url(../../../assets/images/fundo.png) !important;
  background-size: cover !important;
  padding: 50px 0 !important;
  text-align: center !important;

}

.grecaptcha-badge {
  z-index: 9;
}

.carousel-inner {
  overflow: visible;
  z-index: 9 !important;
}
// FAIXA DE CONTRATADO
.card-app {
  height: 250px;
  //width: 270px;
  position: relative;
}

.card-app span {
  position: absolute;
  //background-color: #e53e3e;
  top: -10px;
  left: -10px;
  width: 150px;
  height: 150px;
  //background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 100;
}

.card-app span::before {
  content: "Contratado";
  position: absolute;
  background-color: #2ed8b6;
  width: 250px;
  height: 40px;
  transform: rotate(-45deg) translateY(-20px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  //background: rgba(0, 0, 0, 0.5);
}


// BADGE DE CONTRATADO

// .card-app {
//   height: 250px;
//   position: relative;
// }

// .card-app span {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background-color: #2ed8b6;
//   color: white;
//   padding: 5px 10px;
//   border-radius: 12px;
//   font-weight: 600;
//   text-transform: uppercase;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// }

// BADGE COM CHECK

// .card-app {
//   height: 250px;
//   position: relative;
// }

// .card-app .badge-check-app {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background-color: #2ed8b6;
//   color: white;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// }


// .card-app .badge-check-app::before {
//   content: "✔";
//   font-size: 20px;
//   font-weight: bold;
// }

/* ************************************************************* */

.card-app span::after {
  content: "";
  position: absolute;
  background-color: #2ed8b6;
  width: 10px;
  height: 10px;
  bottom: 0;
  left: 0;

  background-color: #2ed8b6;
  z-index: -1;
  //background: rgba(0, 0, 0, 0.5);
}

.card-app .card-img-app {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 74px;
  top: -48px;
  z-index: 101;
  /* transform: rotate(45deg); */
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5));
}

.card-app .card-img-app-free {
  width: 88px;
  height: 40px;
  position: absolute;
  left: -11px;
  top: 3px;
  z-index: 101;
  /* transform: rotate(45deg); */
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5));
}

.tabela-container {
  position: relative;
}

.lupa-flutuante {
  position: absolute;
  top: 180px;
  right: 120px;
  width: 150px;
  height: 150px;
  z-index: 1;
}

.no-print {
  display: none !important;
}


/* Mudar a cor do info do exportar PDF*/
.icon-hover {
  color: #007bff; /* Cor padrão */
  transition: color 0.3s ease; /* Suaviza a transição de cor */
}

.icon-hover:hover {
  color: #ffffff; /* Cor ao passar o mouse */
}





